<template>
  <div class="bg-grey">
    <div class="content">
      <div class="panel-heading" style="border-bottom: none;">
        <h1 class="color-blue text-center h1-header">
          上海卒中学会会员管理办法<br>
          （征集意见稿）
        </h1>
      </div>
      <div class="panel-body">
        <p class="one-p">
          <b>第一章 总  则</b><br>
          第一条  为规范上海卒中学会会员的管理，根据《上海卒中学会章程》和上海科学技术协会，民政局等关于学术团体会员管理的有关规定，
          结合我会的实际情况，特制定本管理办法。<br>
          第二条  上海卒中学会会员的入会、退会和日常活动的管理，适用本条例（管理办法）。<br>
          第三条  上海卒中学会设学生会员、普通会员、资深会员、单位会员、名誉会员。<br>
          第四条  上海卒中学会会员管理，遵循严格审批、入会自愿、退会自由的原则。<br>
          第五条  上海卒中学会会员遵守国家法律法规和《上海卒中学会章程》的规定，积极参加学会活动，维护学会名誉，为促进医学科技进步
          和卫生事业发展作贡献。<br><br>

          <b>第二章  学生会员</b><br>
          第六条〔入会条件〕 承认上海卒中学会章程，有加入本会意愿，符合下列会员条件者，可申请成为上海卒中学会学生会员：在医学院校
          就读的跟卒中防治相关专业的本（专）科生、硕士研究生、博士研究生。<br>
          第七条〔入会程序〕 有意愿成为上海卒中学会会员者，应由本人填写并提交《上海卒中学会会员入会申请表》，由本会
          会员管理职能部门审批，并予备案。<br>
          第八条〔权利〕 <br>
          &nbsp;&nbsp;（一）享有本会的选举权、被选举权和表决权。<br>
          &nbsp;&nbsp;（二）享有对本会工作的批评、建议权和监督权。<br>
          &nbsp;&nbsp;（三）免费取得本会年度学术活动计划表、相关学术期刊等。<br>
          &nbsp;&nbsp;（四）可优先参与本会组织的国内学术活动。<br>
          &nbsp;&nbsp;（五）入会自愿，退会自由。<br>
          第九条〔义务〕 遵守本会章程。执行本会的决议、决定。参加本会组织的有关社会公益活动。支持本会事业发展，或接受委托依法募
          集资金。维护本会的合法权益。<br><br>

          <b>第三章  普通会员</b><br>
          第十条〔入会条件〕 承认上海卒中学会章程，有加入本会意愿，符合下列会员条件之一者，可申请成为上海卒中学会普通会员：<br>
          &nbsp;&nbsp;（一）医学院校毕业，获得执业许可的住院医师、临床药学、护理、助教、实习研究员、助理主编、技师以上专业技术职务者；<br>
          &nbsp;&nbsp;（二）从事与医学专业有关工作，具备以上相应专业技术职务者。<br>
          第十一条〔入会程序〕 有意愿成为上海卒中学会会员者，应由本人填写并提交《上海卒中学会会员入会申请表》，由本会会员管
          理职能部门审批，并予备案。<br>
          第十二条〔权利〕 <br>
          &nbsp;&nbsp;（一）享有本会的选举权、被选举权和表决权。<br>
          &nbsp;&nbsp;（二）享有对本会工作的批评、建议权和监督权。<br>
          &nbsp;&nbsp;（三）免费取得本会年度学术活动计划表、相关学术期刊等。<br>
          &nbsp;&nbsp;（四）可优先参与本会组织的国内学术活动。<br>
          &nbsp;&nbsp;（五）入会自愿，退会自由。<br>
          第十三条〔义务〕 遵守本会章程。执行本会的决议、决定，完成本会委托的工作任务。参加本会组织的有关社会公益活动。按期
          缴纳会费。支持本会事业发展，或接受委托依法募集资金。维护本会的合法权益。<br><br>

          <b>第四章  资深会员</b><br>
          第十四条〔入会条件〕 本会会员中取得主任医师或相应职称，具有较高的学术威望，在本学科发展中成绩卓著，热心支持本会工作，
          能履行资深会员义务者。<br>
          第十五条〔入会程序〕 资深会员总数原则上不超过本会会员总数的5‰。<br>
          &nbsp;&nbsp;（一）由分会委员会或本会会员管理职能部门提出推荐人选，经分会现任主任委员或会员管理职能部门负责人签字，报上海卒中学会；<br>
          &nbsp;&nbsp;（二）资深会员由常务理事会审批，会长聘任；<br>
          &nbsp;&nbsp;（三）上海卒中学会颁发资深会员证书并通知相关分会与资深会员所在单位。<br>
          第十六条〔权利〕<br>
          &nbsp;&nbsp;（一）终身享有资深会员资格；<br>
          &nbsp;&nbsp;（二）除享有会员的权利外，可免费参加本会组织的国内外学术活动；<br>
          第十七条〔义务〕<br>
          &nbsp;&nbsp;（一）参与对我国卒中防治卫生工作发展战略、政策的重大决策的论证、咨询等活动；<br>
          &nbsp;&nbsp;（二）对学会的工作和事业发展提出批评与建议；<br>
          &nbsp;&nbsp;（三）为发展本会事业自愿募集资金。<br><br>

          <b>第五章  名誉会员</b><br>
          第十八条〔入会条件〕<br>
          &nbsp;&nbsp;（一）著名的外籍医学专家，对我国卒中防治医学科学事业的发展有重要贡献，热心支持本会工作者；<br>
          &nbsp;&nbsp;（二）非卒中防治领域或非医学界的专家和知名人事，热心本会工作，以各种形式给与本会帮助，对我国卒中防控医学科学事业的发展有
          重要贡献，对促进我国与其他国家及地区的友好关系做出重要贡献者。<br>
          第十九条〔入会程序〕须经由上海卒中学会常务理事2名或分会推荐，经会员管理职能部门审议，报常务理事会批准后，由上海卒中学会颁
          发名誉会员证书。<br>
          第二十条〔权利〕<br>
          &nbsp;&nbsp;（一）终生享有名誉会员资格；<br>
          &nbsp;&nbsp;（二）免费取得本会年度活动计划表、工作计划、学术期刊等资料；<br>
          &nbsp;&nbsp;（三）免费参加本会组织的各类学术活动；<br>
          &nbsp;&nbsp;（四）免费取得本会主办的学术会议的论文汇编等资料。<br>
          第二十一条〔义务〕<br>
          &nbsp;&nbsp;（一）遵守本会章程；<br>
          &nbsp;&nbsp;（二）热心本会工作，以各种方式给予本会帮助；<br>
          &nbsp;&nbsp;（三）对本会和相关分会的工作和事业发展提出意见与建议。<br><br>

          <b>第六章  单位（事业/社团）会员</b><br>
          第二十二条〔入会条件〕凡从事与卒中防治医学专业有关的具有一定数量科技人员的医疗卫生、医学教育、医学科研机构与社会团体，承认
          本会章程，愿意参加本会活动，支持本会工作，按时缴纳会费，均可成为本会单位会员，由上海卒中学会直接发展和管理。<br>
          第二十三条〔入会程序〕医疗卫生、医学教育、医学科研机构与社会团体单位提出申请，并填写《上海卒中学会会员入会申请表》报上海卒
          中学会批准并颁发证书。<br>
          第二十四条〔权利〕<br>
          &nbsp;&nbsp;（一）优先参加本会组织的各类学术活动；<br>
          &nbsp;&nbsp;（二）优先取得本会有关学术资料，技术咨询和医学信息；<br>
          &nbsp;&nbsp;（三）优先协办本会举办的各类学术活动；<br>
          &nbsp;&nbsp;（四）对本会工作提出意见和建议。<br>
          第二十五条〔义务〕<br>
          &nbsp;&nbsp;（一）遵守本会章程，执行本会决议及接受本会委托的相关工作；<br>
          &nbsp;&nbsp;（二）协助本会开展有关学术和科普活动；<br>
          &nbsp;&nbsp;（三）为发展本会事业自愿捐赠及募集资金；<br>
          &nbsp;&nbsp;（四）按期缴纳单位（事业/社团）会员会费。<br><br>

          <b>第七章  单位（企业）会员</b><br>
          第二十六条〔入会条件〕凡在国内外具有一定知名度从事医药生产的高新科技企业单位，承认本会章程，按时缴纳会费，支持赞助本会工作
          ，自愿参加本会活动，均可成为本会企业会员。由上海卒中学会直接发展和管理。<br>
          第二十七条〔入会程序〕企业单位提出申请，并填写《上海卒中学会会员入会申请表》报上海卒中学会批准并颁发证书。<br>
          第二十八条〔权利〕<br>
          &nbsp;&nbsp;（一）参加本会组织的相关学术活动，每年1~2次，每次展示优惠5％~10％，并优先取得有关的学术资料；<br>
          &nbsp;&nbsp;（二）申请本会协助举办有关科技咨询活动（培训班，学习班），召开国内、外技术咨询研讨会和学术活动；<br>
          &nbsp;&nbsp;（三）参加本会优秀会员的评选、表彰、奖励的活动；<br>
          &nbsp;&nbsp;（四）对本会的工作有建议权和批评权；<br>
          &nbsp;&nbsp;（五）入会自愿，退会自由。<br>
          第二十九条〔义务〕<br>
          &nbsp;&nbsp;（一）遵守本会章程，执行本会决议，接受本会委托的相关工作；<br>
          &nbsp;&nbsp;（二）支持赞助本会开展的有关学术和科普活动，为发展本会事业自愿捐赠资金；<br>
          &nbsp;&nbsp;（三）按期缴纳企业会员会费。<br><br>

          <b>第八章  会费管理</b><br>
          第三十条〔会费缴纳标准〕<br>
          &nbsp;&nbsp;（一）普通会员会费标准：50元/人/年；<br>
          &nbsp;&nbsp;（二）单位（事业/社团）会员标准：地市级及以下6000元/年，省级及以上10000元/年；<br>
          &nbsp;&nbsp;（三）单位（企业）会员标准：200000元/年；<br>
          &nbsp;&nbsp;（四）学生会员、资深会员、名誉会员免缴会费。<br>
          第三十一条〔会费缴费时间及其他要求〕上海卒中学会所设各类会员均在每年3月31日前缴纳当年会费。<br>
          第三十二条〔会费用途〕<br>
          &nbsp;&nbsp;（一）用于本会开展的学术活动；<br>
          &nbsp;&nbsp;（二）用于本会举办的各种类型的专题讲座、继续医学教育、医学科普活动等其他公益性活动；<br>
          &nbsp;&nbsp;（三）用于会员免费获得各类期刊和资料的成本支出；<br>
          &nbsp;&nbsp;（四）用于奖励有学术成就的会员和优秀论文作者；<br>
          &nbsp;&nbsp;（五）用于奖励有突出贡献的会员；<br>
          &nbsp;&nbsp;（六）用于本会为会员服务的管理费用；<br>
          第三十三条〔会员管理财务制度〕会费管理按上海卒中学会的有关财务制度办理。<br><br>

          <b>第九章 附  则</b><br>
          第三十四条〔对取消会员资格的规定和退会程序〕<br>
          &nbsp;&nbsp;（一）上海卒中学会会员入会自愿，退会自由。<br>
          &nbsp;&nbsp;（二）各类会员一年不缴纳会费、不参加学会组织的活动，按自动退会处理；<br>
          &nbsp;&nbsp;（三）不得从事违反本会章程和有损本会声誉的一切活动；未经本会允许，不得擅自以本会名义从事商业活动；<br>
          &nbsp;&nbsp;（四）上海卒中学会会员违反前款规定，经常务理事会或会员管理职能部门决定，取消其会员资格，并收回会员证书。<br>
          第三十五条〔实施时间〕本办法自发布之日起施行。<br>
        </p><p class="text-center">
      </p>
        <p></p>
      </div>
    </div>
  </div>

</template>

<script>
  import mixs from "@/mixs"
  export default {
    name: "Home",
    inject: ['language'],
    mixins: [mixs],
    data() {
      return {

      }
    },
    created() {

    },
    computed: {

    },
    mounted() {

    },
  }
</script>
<style lang="less">
.content {
  width: 1200px;
  margin: 20px auto;

}
.panel-heading {
  width: 100%;
  height: 95px;
  padding: 30px;
  border-top: 3px solid #004DA2;
  border-bottom: 1px solid #EEEEEE;
  background-color: #fff;
  box-sizing: border-box;
}
.panel-body {
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
}
.color-blue {
  color: #014DA3;
}


.one-p {
  font-size: 16px;
  line-height: 32px;
  text-align: justify;
  margin: 40px 0;
  /* text-indent: 32px; */
}
.text-center {
  text-align: center;

}
.bg-grey {
  background-color: #FBFBFB;
}
.h1-header{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

</style>
